import React from 'react';
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-router-dom';

// Images:

import malebody_orange from '../assets/PNGs/malebody_orange.png';
import femalebody_orange from '../assets/PNGs/femalebody_orange.png';
import female_head from '../assets/PNGs/female_head.png';
import male_head from '../assets/PNGs/male_head.png';
import male_shoulder from '../assets/PNGs/male_shoulder.png';
import female_shoulder from '../assets/PNGs/female_shoulder.png';
import male_neck from '../assets/PNGs/male_neck.png';
import female_neck from '../assets/PNGs/female_neck.png';
import male_center_chest from '../assets/PNGs/male_center_chest.png';
import female_center_chest from '../assets/PNGs/female_center_chest.png';
import male_stomach from '../assets/PNGs/male_stomach.png';
import female_stomach from '../assets/PNGs/female_stomach.png';
import male_hips from '../assets/PNGs/male_hips.png';
import female_hips from '../assets/PNGs/female_hips.png';
import human_palm from '../assets/PNGs/human_palm.png';
// import human_finger from '../assets/PNGs/human_finger.png';
import human_foot from '../assets/PNGs/human_foot.png';
// import human_ankle from '../assets/PNGs/human_ankle.png';
import knees from '../assets/PNGs/male_knees.png';
import back from '../assets/PNGs/back.png'

//   SVGs  //
import mail from '../assets/SVGs/mail.svg';

import { Container, Card } from 'reactstrap';

class Home extends React.Component {

    constructor() {
        super();
        this.state = {
            isShowaMale: false,
            isShowdiv: false,
            isModalOpen: false,
            show_pain_details: false,
        };

        this.toggleShowMale = this.toggleShowMale.bind(this);
        this.togglediv = this.togglediv.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggle_show_pain_details = this.toggle_show_pain_details.bind(this);

    }

    togglediv(show) {
        this.setState({ isShowdiv: show });
    }

    toggleShowMale(show) {
        this.setState({ isShowaMale: show });
        this.togglediv(true);
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    toggle_show_pain_details() {
        this.setState({
            show_pain_details: !this.state.show_pain_details
        })
    }

    render() {

        const { isShowaMale, isShowdiv } = this.state;

        return (
            <Container className="py-5">

                <a href="mailto:contact@whythishurts.com" target="_blank"><img className="contact-btn" src={mail} alt="Contact Us" /></a>

                {/* <img src={Doctors} className="mb-5 pb-3" alt="Doctors" width="70%"/> */}

                <div className="row">
                    <a className="btn col-6 bg-transparent" onClick={() => this.toggleShowMale(true)}  >
                        <img className="humanbody hov_effect" src={malebody_orange} alt="Male" />
                    </a>
                    <a className="btn col-6 bg-transparent" onClick={() => this.toggleShowMale(false)}  >
                        <img className="humanbody hov_effect" src={femalebody_orange} alt="Female" />
                    </a>
                </div>
                <br />

                {isShowdiv ? (<p>Tell us, where does it hurt.</p>) : (<p>Select your gender from above.</p>)}

                {/* Hidden div */}
                {isShowdiv && (
                    <Zoom center>
                        <div id="body-parts" className="row mb-4">
                            <div className="mx-auto">
                                <Card className="mx-auto w-75 bg-grey container">
                                    <div className="row">
                                        <Card className="option-card m-2">
                                            <Link to={`${isShowaMale ? "/head-male" : "/head-female"}`} className="btn bg-transparent col-4">
                                                <img src={`${isShowaMale ? male_head : female_head}`} className="body-part" alt="Head" />
                                            </Link>
                                        </Card>
                                        <Card className="option-card m-2">
                                            <Link to={`${isShowaMale ? "/neck-male" : "/neck-female"}`} className="btn bg-transparent col-4">
                                                <img src={`${isShowaMale ? male_neck : female_neck}`} className="body-part" alt="Neck" />
                                            </Link>
                                        </Card>
                                        <Card className="option-card m-2">
                                            <Link to={`${isShowaMale ? "/hand-male" : "/hand-female"}`} className="btn bg-transparent col-4">
                                                <img src={human_palm} className="body-part" alt="Hand" />
                                            </Link>
                                        </Card>
                                    </div>

                                    <div className="row">
                                        <Card className="option-card m-2">
                                            <Link to={`${isShowaMale ? "/chest-male" : "/chest-female"}`} className="btn bg-transparent col-4">
                                                <img src={`${isShowaMale ? male_center_chest : female_center_chest}`} className="body-part" alt="Central Chest Area" />
                                            </Link>
                                        </Card>
                                        <Card className="option-card m-2">
                                            <Link to={`${isShowaMale ? "/stomach-male" : "/stomach-female"}`} className="btn bg-transparent col-4">
                                                <img src={`${isShowaMale ? male_stomach : female_stomach}`} className="body-part" alt="Stomach Area" />
                                            </Link>
                                        </Card>
                                        <Card className="option-card m-2">
                                            <Link to={`${isShowaMale ? "/shoulder-male" : "/shoulder-female"}`} className="btn bg-transparent col-4">
                                                <img src={`${isShowaMale ? male_shoulder : female_shoulder}`} className="body-part" alt="Heart area or Chest" />
                                            </Link>
                                        </Card>
                                    </div>

                                    <div className="row">
                                        <Card className="option-card m-2">
                                            <Link to={`${isShowaMale ? "/hip-male" : "/hip-female"}`} className="btn bg-transparent col-4">
                                                <img src={`${isShowaMale ? male_hips : female_hips}`} className="body-part" alt="Hips" />
                                            </Link>
                                        </Card>
                                        <Card className="option-card m-2 mx-auto">
                                            <Link to="/knee" className="btn bg-transparent col-4">
                                                <img src={knees} className="body-part" alt="Knees" />
                                            </Link>
                                        </Card>
                                        <Card className="option-card m-2 mx-auto">
                                            <Link to={`${isShowaMale ? "/foot-male" : "/foot-female"}`} className="btn bg-transparent col-4">
                                                <img src={human_foot} className="body-part" alt="Foot" />
                                            </Link>
                                        </Card>
                                    </div>

                                    <div className="row">
                                        <Card className="option-card m-2 mx-auto">
                                            <Link to="back" className="btn bg-transparent col-4">
                                                <img src={back} className="body-part" alt="Back" />
                                            </Link>
                                        </Card>

                                    </div>
                                </Card>
                            </div>
                        </div>
                    </Zoom>
                )}


                <Zoom center>
                    <div className="row my-0 py-0">
                        <div className="col-12 col-lg p-5 text-left">
                            Why this hurts gives you probable reasons for your pain based on your symptoms. Select your gender, region where you are experiencing pain and then you can get an idea of why it hurts. For more information, or if you have any questions or feedback, feel free to reach out to us at <a href="mailto:contact@whythishurts.com" target="_blank">contact@whythishurts.com</a>
                        </div>
                        <div className="col-12 col-lg px-5 text-left">
                            <img src='https://opendoodles.s3-us-west-1.amazonaws.com/levitate.gif' width="100%" alt="Floating Lady" align="middle" />
                        </div>
                    </div>
                </Zoom>
                <Zoom center>
                    <div className="row px-5 border rounded shadow">
                        <div className="doctor_profile col-12 py-3">
                            <p className="h3 text-center">About the author</p>
                            <p className="h4">Sanjeev Kumar, MD</p>
                            <p className="h5 w-50 mx-auto" style={{ color: "grey" }}>Associate Professor Of Anesthesiology/Pain Medicine; Medical Director, Springhill Pain Clinic</p>
                            <p className="h5 mx-auto" style={{ color: "grey" }}>University of Florida</p>
                            <p className="lead text-justify" style={{ fontSize: "smaller" }}>Dr. Kumar earned his Bachelor of Medicine, Bachelor of Surgery from Delhi University in India. He trained at Delhi University/ Safdarjung Hospital for his residency training in Anesthesiology. Dr. Kumar then interned and was a resident at Wayne State University/ Detroit Medical Center in Detroit, MI. He completed his fellowship training in Pain Medicine there as well. He is certified in Anesthesiology and Pain Medicine by the American Board of Anesthesiology. Dr. Kumar has extensive clinical, research & editorial experience. He has served as a member of editorial board of different pain journals. He is a member of several professional societies such as the North American Spine Society, American Society of Anesthesiology etc. and a Lifetime Secretary of Society for Awareness in Medical Emergency. He is the Medical Director of Pain Medicine Clinic at UF Health. He pioneered Endoscopic Spine Decompression surgeries at UF Health and put UF Health on the select list of institutions where patients have access to cutting edge minimally invasive spine surgery.</p>
                            <a href="https://anest.ufl.edu/profile/kumar-sanjeev/" className="btn btn-secondary a-link">Know More</a>
                        </div>
                    </div>
                </Zoom>
            </Container>
        )
    }
}

export default Home;